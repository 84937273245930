import * as React from "react"
import { contactHolder } from './Pages.module.scss'


// markup
const ContactPage = () => {
  return (
    <article className={contactHolder}>
      <section>
        <p>Management: <a href="mailto:richlegate@gmail.com" title="Contact Kyla La Grange">richlegate@gmail.com</a></p>
        <p>Press: <a href="mailto:Elliot.Mitchell@zeitgeist.co.uk" title="Contact Kyla La Grange">Elliot.Mitchell@zeitgeist.co.uk</a></p>
        <p>Bookings: <a href="mailto:rob@fmly.agency" title="Kyla La Grange - live bookings">rob@fmly.agency</a></p>
      </section>
    </article>
  )
}

export default ContactPage